import React, { useState, useCallback } from 'react';
import CountrySelector from './CountrySelector';


const countryPackages = {
  AT: [
    { id: 1, duration: 3, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Vienna city tour', 'Schönbrunn Palace', 'St. Stephen\'s Cathedral'] },
    { id: 2, duration: 5, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Salzburg Mozart tour', 'Hallstatt village visit', 'Alpine skiing experience'] },
    { id: 3, duration: 7, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Innsbruck old town tour', 'Grossglockner drive', 'Wachau Valley wine tasting'] },
  ],
  CA: [
    { id: 1, duration: 3, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['CN Tower visit', 'Niagara Falls tour', 'Old Quebec walk'] },
    { id: 2, duration: 5, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Banff National Park tour', 'Vancouver island visit', 'Montreal food tour'] },
    { id: 3, duration: 7, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Rocky Mountaineer train', 'Northern Lights viewing', 'Prince Edward Island tour'] },
  ],
  CO: [
    { id: 1, duration: 3, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Cartagena old town', 'Monserrate cable car', 'Gold Museum visit'] },
    { id: 2, duration: 5, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Coffee region tour', 'Tayrona National Park', 'Medellin innovation tour'] },
    { id: 3, duration: 7, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Lost City trek', 'Cocora Valley hike', 'San Andres island tour'] },
  ],
  HR: [
    { id: 1, duration: 3, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Dubrovnik walls walk', 'Split palace tour', 'Zagreb cathedral visit'] },
    { id: 2, duration: 5, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Plitvice Lakes tour', 'Hvar island hopping', 'Istrian wine tasting'] },
    { id: 3, duration: 7, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Korčula Marco Polo tour', 'Krka waterfalls visit', 'Zadar sunset experience'] },
  ],
  CY: [
    { id: 1, duration: 3, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Paphos Archaeological Park tour', 'Limassol Castle visit', 'Larnaca salt lake walk'] },
    { id: 2, duration: 5, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Troodos Mountains hiking', 'Nicosia divided city tour', 'Ayia Napa beach day'] },
    { id: 3, duration: 7, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Akamas Peninsula jeep safari', 'Kyrenia harbor exploration', 'Traditional meze dinner'] },
  ],
  CZ: [
    { id: 1, duration: 3, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Prague Castle tour', 'Charles Bridge walk', 'Old Town Square exploration'] },
    { id: 2, duration: 5, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Cesky Krumlov day trip', 'Karlovy Vary spa experience', 'Bohemian Paradise hiking'] },
    { id: 3, duration: 7, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Moravian wine tasting', 'Brno underground tour', 'Karlstejn Castle visit'] },
  ],
  'GB-ENG': [
    { id: 1, duration: 3, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Tower of London tour', 'Westminster Abbey visit', 'Thames River cruise'] },
    { id: 2, duration: 5, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Stonehenge day trip', 'Bath Roman baths', 'Cotswolds village tour'] },
    { id: 3, duration: 7, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Lake District hiking', 'York medieval city walk', 'Shakespeare\'s Stratford'] },
  ],
  FR: [
    { id: 1, duration: 3, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Eiffel Tower visit', 'Louvre Museum tour', 'Seine River cruise'] },
    { id: 2, duration: 5, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Versailles Palace day trip', 'Mont Saint-Michel visit', 'Wine tasting in Bordeaux'] },
    { id: 3, duration: 7, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['French Riviera tour', 'Provence lavender fields', 'Loire Valley castles'] },
  ],
  DE: [
    { id: 1, duration: 3, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Brandenburg Gate tour', 'Reichstag Building visit', 'Berlin Wall walk'] },
    { id: 2, duration: 5, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Neuschwanstein Castle trip', 'Munich Oktoberfest', 'Black Forest hiking'] },
    { id: 3, duration: 7, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Rhine Valley cruise', 'Hamburg harbor tour', 'Dresden old town visit'] },
  ],
  GR: [
    { id: 1, duration: 3, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Acropolis tour', 'Plaka neighborhood walk', 'Temple of Zeus visit'] },
    { id: 2, duration: 5, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Santorini sunset viewing', 'Mykonos beach day', 'Delphi oracle tour'] },
    { id: 3, duration: 7, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Meteora monasteries visit', 'Crete palace tour', 'Rhodes medieval city walk'] },
  ],
  IS: [
    { id: 1, duration: 3, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Golden Circle tour', 'Blue Lagoon spa', 'Reykjavik city walk'] },
    { id: 2, duration: 5, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['South Coast waterfalls', 'Jökulsárlón Glacier Lagoon', 'Ice cave exploration'] },
    { id: 3, duration: 7, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Snæfellsnes Peninsula', 'Whale watching in Húsavík', 'Northern Lights hunt'] },
  ],
  ID: [
    { id: 1, duration: 3, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Ubud monkey forest', 'Rice terrace walk', 'Temple ceremony experience'] },
    { id: 2, duration: 5, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Mount Batur sunrise trek', 'Nusa Penida island tour', 'Traditional dance show'] },
    { id: 3, duration: 7, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Gili Islands snorkeling', 'Tanah Lot sunset view', 'Cooking class experience'] },
  ],
  IE: [
    { id: 1, duration: 3, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Dublin city tour', 'Cliffs of Moher visit', 'Guinness Storehouse'] },
    { id: 2, duration: 5, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Ring of Kerry scenic drive', 'Blarney Castle tour', 'Traditional music night'] },
    { id: 3, duration: 7, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Giant\'s Causeway exploration', 'Belfast political tour', 'Aran Islands trip'] },
  ],
  IL: [
    { id: 1, duration: 3, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Jerusalem Old City tour', 'Western Wall visit', 'Tel Aviv beach day'] },
    { id: 2, duration: 5, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Dead Sea floating', 'Masada fortress tour', 'Galilee exploration'] },
    { id: 3, duration: 7, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Petra day trip', 'Eilat Red Sea diving', 'Nazareth historical tour'] },
  ],
  IT: [
    { id: 1, duration: 3, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Colosseum tour', 'Vatican Museums visit', 'Pasta making class'] },
    { id: 2, duration: 5, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Venice gondola ride', 'Florence art tour', 'Tuscan countryside'] },
    { id: 3, duration: 7, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Amalfi Coast drive', 'Pompeii archaeological site', 'Sicily culinary tour'] },
  ],
  JP: [
    { id: 1, duration: 3, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Tokyo city tour', 'Mount Fuji visit', 'Traditional tea ceremony'] },
    { id: 2, duration: 5, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Kyoto temples tour', 'Osaka food adventure', 'Bullet train experience'] },
    { id: 3, duration: 7, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Hiroshima Peace Memorial', 'Nara deer park', 'Hakone hot springs'] },
  ],
  MX: [
    { id: 1, duration: 3, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Teotihuacan pyramids', 'Frida Kahlo museum', 'Xochimilco canals'] },
    { id: 2, duration: 5, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Chichen Itza tour', 'Tulum beaches', 'Oaxaca cooking class'] },
    { id: 3, duration: 7, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Copper Canyon train', 'Monarch butterfly reserve', 'Puebla colonial tour'] },
  ],
  MA: [
    { id: 1, duration: 3, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Marrakech medina tour', 'Atlas Mountains visit', 'Spice market walk'] },
    { id: 2, duration: 5, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Sahara desert camp', 'Fes medieval tour', 'Chefchaouen blue city visit'] },
    { id: 3, duration: 7, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Casablanca mosque tour', 'Essaouira coastal walk', 'Traditional hammam'] },
  ],
  NL: [
    { id: 1, duration: 3, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Canal boat tour', 'Van Gogh Museum visit', 'Royal Palace tour'] },
    { id: 2, duration: 5, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Windmill village visit', 'Keukenhof gardens tour', 'Cheese market'] },
    { id: 3, duration: 7, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Rotterdam architecture tour', 'Dutch countryside cycling', 'Delft pottery workshop'] },
  ],
  PL: [
    { id: 1, duration: 3, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Warsaw Old Town tour', 'Chopin concert', 'POLIN Museum visit'] },
    { id: 2, duration: 5, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Auschwitz-Birkenau memorial', 'Wieliczka Salt Mine', 'Krakow Jewish Quarter'] },
    { id: 3, duration: 7, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Gdansk Hanseatic League tour', 'Malbork Castle trip', 'Bialowieza Forest'] },
  ],
  PT: [
    { id: 1, duration: 3, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Lisbon tram ride', 'Belém Tower visit', 'Fado music night'] },
    { id: 2, duration: 5, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Porto wine tasting', 'Sintra palace tour', 'Algarve beach day'] },
    { id: 3, duration: 7, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Douro Valley tour', 'Madeira island visit', 'Évora Roman temple tour'] },
  ],
  RO: [
    { id: 1, duration: 3, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Bran Castle tour', 'Bucharest Palace', 'Old Town walking tour'] },
    { id: 2, duration: 5, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Transylvania circuit', 'Painted monasteries', 'Peleș Castle tour'] },
    { id: 3, duration: 7, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Carpathian hiking', 'Black Sea resort stay', 'Danube Delta cruise'] },
  ],
  'GB-SCT': [
    { id: 1, duration: 3, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Edinburgh Castle tour', 'Royal Mile walk', 'Whisky tasting experience'] },
    { id: 2, duration: 5, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Loch Ness boat trip', 'Highland games attendance', 'Isle of Skye scenic drive'] },
    { id: 3, duration: 7, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['St Andrews golf course visit', 'Stirling Castle exploration', 'Scottish Highlands hiking'] },
  ],
  ZA: [
    { id: 1, duration: 3, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Kruger safari drive', 'Table Mountain cable car', 'Robben Island tour'] },
    { id: 2, duration: 5, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Cape Winelands tour', 'Garden Route drive', 'Johannesburg apartheid museum'] },
    { id: 3, duration: 7, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Victoria Falls visit', 'Whale watching trip', 'Drakensberg hiking'] },
  ],
  ES: [
    { id: 1, duration: 3, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Sagrada Familia tour', 'Park Güell visit', 'La Rambla walk'] },
    { id: 2, duration: 5, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Alhambra Palace exploration', 'Seville Flamenco show', 'Toledo day trip'] },
    { id: 3, duration: 7, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['San Sebastian culinary tour', 'Camino de Santiago walk', 'Ibiza beach relaxation'] },
  ],
  CH: [
    { id: 1, duration: 3, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Matterhorn viewing', 'Lucerne lake tour', 'Geneva old town walk'] },
    { id: 2, duration: 5, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Jungfraujoch railway trip', 'Interlaken paragliding', 'Zurich city tour'] },
    { id: 3, duration: 7, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Swiss Alps hiking', 'Chocolate factory tour', 'Lake Geneva cruise'] },
  ],
  TH: [
    { id: 1, duration: 3, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Bangkok Grand Palace tour', 'Wat Pho temple visit', 'Chao Phraya River cruise'] },
    { id: 2, duration: 5, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Chiang Mai elephant sanctuary', 'Thai cooking class', 'Doi Suthep temple hike'] },
    { id: 3, duration: 7, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Phuket island hopping', 'Ayutthaya ruins exploration', 'Koh Samui beach relaxation'] },
  ],
  TR: [
    { id: 1, duration: 3, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Hagia Sophia visit', 'Blue Mosque tour', 'Grand Bazaar shopping'] },
    { id: 2, duration: 5, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Cappadocia balloon ride', 'Pamukkale thermal pools', 'Ephesus ruins tour'] },
    { id: 3, duration: 7, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Turkish bath experience', 'Bosphorus cruise', 'Troy ancient city visit'] },
  ],
  AE: [
    { id: 1, duration: 3, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Burj Khalifa visit', 'Desert safari', 'Dubai Mall shopping'] },
    { id: 2, duration: 5, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Abu Dhabi day trip', 'Gold Souk tour', 'Palm Jumeirah exploration'] },
    { id: 3, duration: 7, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Dubai Marina cruise', 'Miracle Garden visit', 'Burj Al Arab experience'] },
  ],
  US: [
    { id: 1, duration: 3, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Statue of Liberty tour', 'Times Square visit', 'Central Park walk'] },
    { id: 2, duration: 5, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Grand Canyon exploration', 'Las Vegas strip tour', 'Hollywood walk of fame'] },
    { id: 3, duration: 7, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['San Francisco bay tour', 'Miami beach experience', 'Disney World visit'] },
  ],
  VN: [
    { id: 1, duration: 3, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Ha Long Bay cruise', 'Hanoi Old Quarter', 'Cu Chi tunnels'] },
    { id: 2, duration: 5, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Hoi An ancient town', 'Mekong Delta tour', 'Sapa rice terraces'] },
    { id: 3, duration: 7, price: 20, stripeLink: "YOUR_STRIPE_LINK", highlights: ['Phong Nha caves', 'Hue Imperial City', 'Phu Quoc island resort'] },
  ],
};

const countryInfo = {
  AT: {
    name: 'Austria',
    code: 'AT',
    capital: 'Vienna',
    language: 'German',
    currency: 'Euro (€)',
    bestTimeToVisit: 'April to October',
    timeZone: 'CET (UTC+1)',
    attractions: ['Schönbrunn Palace', 'Salzburg', 'Hallstatt'],
  },
  CA: {
    name: 'Canada',
    code: 'CA',
    capital: 'Ottawa',
    language: 'English, French',
    currency: 'Canadian Dollar (CAD)',
    bestTimeToVisit: 'June to September',
    timeZone: 'Multiple zones (UTC-3.5 to -8)',
    attractions: ['Banff National Park', 'Niagara Falls', 'CN Tower'],
  },
  CO: {
    name: 'Colombia',
    code: 'CO',
    capital: 'Bogotá',
    language: 'Spanish',
    currency: 'Colombian Peso (COP)',
    bestTimeToVisit: 'December to March',
    timeZone: 'COT (UTC-5)',
    attractions: ['Cartagena', 'Coffee Region', 'Lost City'],
  },
  HR: {
    name: 'Croatia',
    code: 'HR',
    capital: 'Zagreb',
    language: 'Croatian',
    currency: 'Euro (€)',
    bestTimeToVisit: 'May to October',
    timeZone: 'CET (UTC+1)',
    attractions: ['Dubrovnik Old Town', 'Plitvice Lakes', 'Split Palace'],
  },
  CY: {
    name: 'Cyprus',
    code: 'CY',
    capital: 'Nicosia',
    language: 'Greek, Turkish',
    currency: 'Euro (€)',
    bestTimeToVisit: 'April to October',
    timeZone: 'EET (UTC+2)',
    attractions: ['Paphos Archaeological Park', 'Kourion', 'Nissi Beach'],
  },
  CZ: {
    name: 'Czech Republic',
    code: 'CZ',
    capital: 'Prague',
    language: 'Czech',
    currency: 'Czech Koruna (CZK)',
    bestTimeToVisit: 'May to September',
    timeZone: 'CET (UTC+1)',
    attractions: ['Prague Castle', 'Charles Bridge', 'Cesky Krumlov'],
  },
  'GB-ENG': {
    name: 'England',
    code: 'GB-ENG',
    capital: 'London',
    language: 'English',
    currency: 'Pound Sterling (£)',
    bestTimeToVisit: 'May to September',
    timeZone: 'GMT (UTC+0)',
    attractions: ['Tower of London', 'Stonehenge', 'Lake District'],
  },
  FR: {
    name: 'France',
    code: 'FR',
    capital: 'Paris',
    language: 'French',
    currency: 'Euro (€)',
    bestTimeToVisit: 'April to October',
    timeZone: 'CET (UTC+1)',
    attractions: ['Eiffel Tower', 'Louvre Museum', 'Palace of Versailles'],
  },
  DE: {
    name: 'Germany',
    code: 'DE',
    capital: 'Berlin',
    language: 'German',
    currency: 'Euro (€)',
    bestTimeToVisit: 'May to September',
    timeZone: 'CET (UTC+1)',
    attractions: ['Neuschwanstein Castle', 'Brandenburg Gate', 'Black Forest'],
  },
  GR: {
    name: 'Greece',
    code: 'GR',
    capital: 'Athens',
    language: 'Greek',
    currency: 'Euro (€)',
    bestTimeToVisit: 'April to October',
    timeZone: 'EET (UTC+2)',
    attractions: ['Acropolis', 'Santorini', 'Meteora'],
  },
  IS: {
    name: 'Iceland',
    code: 'IS',
    capital: 'Reykjavik',
    language: 'Icelandic',
    currency: 'Icelandic Króna (ISK)',
    bestTimeToVisit: 'June to August',
    timeZone: 'GMT (UTC+0)',
    attractions: ['Blue Lagoon', 'Golden Circle', 'Vatnajökull Glacier'],
  },
  ID: {
    name: 'Indonesia',
    code: 'ID',
    capital: 'Jakarta',
    language: 'Indonesian',
    currency: 'Indonesian Rupiah (Rp)',
    bestTimeToVisit: 'April to October',
    timeZone: 'Multiple zones (UTC+7 to +9)',
    attractions: ['Bali Temples', 'Borobudur', 'Komodo Island'],
  },
  IE: {
    name: 'Ireland',
    code: 'IE',
    capital: 'Dublin',
    language: 'English, Irish',
    currency: 'Euro (€)',
    bestTimeToVisit: 'April to October',
    timeZone: 'GMT (UTC+0)',
    attractions: ['Cliffs of Moher', 'Guinness Storehouse', 'Trinity College Dublin'],
  },
  IL: {
    name: 'Israel',
    code: 'IL',
    capital: 'Jerusalem',
    language: 'Hebrew, Arabic',
    currency: 'Israeli Shekel (₪)',
    bestTimeToVisit: 'March to May and September to November',
    timeZone: 'IST (UTC+2)',
    attractions: ['Western Wall', 'Dead Sea', 'Tel Aviv Beaches'],
  },
  IT: {
    name: 'Italy',
    code: 'IT',
    capital: 'Rome',
    language: 'Italian',
    currency: 'Euro (€)',
    bestTimeToVisit: 'April to June, September to October',
    timeZone: 'CET (UTC+1)',
    attractions: ['Colosseum', 'Venice Canals', 'Florence Cathedral'],
  },
  JP: {
    name: 'Japan',
    code: 'JP',
    capital: 'Tokyo',
    language: 'Japanese',
    currency: 'Japanese Yen (¥)',
    bestTimeToVisit: 'March to May and September to November',
    timeZone: 'JST (UTC+9)',
    attractions: ['Mount Fuji', 'Fushimi Inari Shrine', 'Tokyo Skytree'],
  },
  MX: {
    name: 'Mexico',
    code: 'MX',
    capital: 'Mexico City',
    language: 'Spanish',
    currency: 'Mexican Peso (MXN)',
    bestTimeToVisit: 'December to April',
    timeZone: 'Multiple zones (UTC-5 to -8)',
    attractions: ['Chichen Itza', 'Teotihuacan', 'Cancun'],
  },
  MA: {
    name: 'Morocco',
    code: 'MA',
    capital: 'Rabat',
    language: 'Arabic, Berber',
    currency: 'Moroccan Dirham (MAD)',
    bestTimeToVisit: 'March to May and September to November',
    timeZone: 'WEST (UTC+1)',
    attractions: ['Marrakech Medina', 'Sahara Desert', 'Fes'],
  },
  NL: {
    name: 'Netherlands',
    code: 'NL',
    capital: 'Amsterdam',
    language: 'Dutch',
    currency: 'Euro (€)',
    bestTimeToVisit: 'April to October',
    timeZone: 'CET (UTC+1)',
    attractions: ['Canal Ring', 'Keukenhof Gardens', 'Van Gogh Museum'],
  },
  PL: {
    name: 'Poland',
    code: 'PL',
    capital: 'Warsaw',
    language: 'Polish',
    currency: 'Polish Złoty (PLN)',
    bestTimeToVisit: 'May to September',
    timeZone: 'CET (UTC+1)',
    attractions: ['Wawel Castle', 'Auschwitz-Birkenau', 'Wieliczka Salt Mine'],
  },
  PT: {
    name: 'Portugal',
    code: 'PT',
    capital: 'Lisbon',
    language: 'Portuguese',
    currency: 'Euro (€)',
    bestTimeToVisit: 'March to May or September to October',
    timeZone: 'WET (UTC+0)',
    attractions: ['Belém Tower', 'Porto', 'Algarve'],
  },
  RO: {
    name: 'Romania',
    code: 'RO',
    capital: 'Bucharest',
    language: 'Romanian',
    currency: 'Romanian Leu (RON)',
    bestTimeToVisit: 'May to September',
    timeZone: 'EET (UTC+2)',
    attractions: ['Bran Castle', 'Painted Monasteries', 'Transylvania'],
  },
  'GB-SCT': {
    name: 'Scotland',
    code: 'GB-SCT',
    capital: 'Edinburgh',
    language: 'English, Scots, Scottish Gaelic',
    currency: 'Pound Sterling (£)',
    bestTimeToVisit: 'May to September',
    timeZone: 'GMT (UTC+0)',
    attractions: ['Edinburgh Castle', 'Loch Ness', 'Isle of Skye'],
  },
  ZA: {
    name: 'South Africa',
    code: 'ZA',
    capital: 'Pretoria',
    language: '11 official languages',
    currency: 'South African Rand (ZAR)',
    bestTimeToVisit: 'May to September',
    timeZone: 'SAST (UTC+2)',
    attractions: ['Kruger National Park', 'Table Mountain', 'Cape Winelands'],
  },
  ES: {
    name: 'Spain',
    code: 'ES',
    capital: 'Madrid',
    language: 'Spanish',
    currency: 'Euro (€)',
    bestTimeToVisit: 'April to October',
    timeZone: 'CET (UTC+1)',
    attractions: ['Sagrada Familia', 'Alhambra', 'Plaza de España'],
  },
  CH: {
    name: 'Switzerland',
    code: 'CH',
    capital: 'Bern',
    language: 'German, French, Italian, Romansh',
    currency: 'Swiss Franc (CHF)',
    bestTimeToVisit: 'June to September',
    timeZone: 'CET (UTC+1)',
    attractions: ['Matterhorn', 'Lake Geneva', 'Jungfraujoch'],
  },
  TH: {
    name: 'Thailand',
    code: 'TH',
    capital: 'Bangkok',
    language: 'Thai',
    currency: 'Thai Baht (฿)',
    bestTimeToVisit: 'November to April',
    timeZone: 'ICT (UTC+7)',
    attractions: ['Grand Palace', 'Phi Phi Islands', 'Wat Pho'],
  },
  TR: {
    name: 'Turkey',
    code: 'TR',
    capital: 'Ankara',
    language: 'Turkish',
    currency: 'Turkish Lira (₺)',
    bestTimeToVisit: 'April to May and September to November',
    timeZone: 'TRT (UTC+3)',
    attractions: ['Hagia Sophia', 'Cappadocia', 'Pamukkale'],
  },
  AE: {
    name: 'United Arab Emirates',
    code: 'AE',
    capital: 'Abu Dhabi',
    language: 'Arabic',
    currency: 'UAE Dirham (AED)',
    bestTimeToVisit: 'November to March',
    timeZone: 'GST (UTC+4)',
    attractions: ['Burj Khalifa', 'Sheikh Zayed Mosque', 'Dubai Mall'],
  },
  US: {
    name: 'United States',
    code: 'US',
    capital: 'Washington, D.C.',
    language: 'English',
    currency: 'US Dollar ($)',
    bestTimeToVisit: 'May to September',
    timeZone: 'Multiple zones (UTC-4 to -10)',
    attractions: ['Statue of Liberty', 'Grand Canyon', 'Times Square'],
  },
  VN: {
    name: 'Vietnam',
    code: 'VN',
    capital: 'Hanoi',
    language: 'Vietnamese',
    currency: 'Vietnamese Dong (₫)',
    bestTimeToVisit: 'February to April and August to October',
    timeZone: 'ICT (UTC+7)',
    attractions: ['Ha Long Bay', 'Hoi An', 'Cu Chi Tunnels'],
  },
};

const PurchasePage = () => {
  const [selectedCountry, setSelectedCountry] = useState(null);

  const handleCountrySelect = useCallback((country) => {
    setSelectedCountry(country);
  }, []);

  return (
    <div id="trips" className="container mx-auto px-4 py-16">
      <h1 className="text-4xl font-bold mb-8 text-center text-gray-800">Choose Your Travel Itinerary</h1>
      <CountrySelector 
        onSelect={handleCountrySelect} 
        packages={countryPackages} 
        countryInfo={countryInfo}
      />
    </div>
  );
};

export default PurchasePage;