import React, { useState } from 'react';
import { ChevronDown } from 'lucide-react';
import Flag from 'react-world-flags';
import PackageDropdown from './PackageDropdown';

// Define which countries are available - can be updated as itineraries become ready
const AVAILABLE_COUNTRIES = [
  // Add country codes here as they become available
  // Example: France and Italy are available
  //const AVAILABLE_COUNTRIES = ['FR', 'IT'];
];

const CountrySelector = ({ onSelect, packages, countryInfo }) => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSelect = (country) => {
    // Only allow selection of available countries
    if (AVAILABLE_COUNTRIES.includes(country.code)) {
      setSelectedCountry(country);
      setIsModalOpen(true);
      onSelect(country);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Sort countries: available ones first, then alphabetically within each group
  const sortedCountries = Object.values(countryInfo).sort((a, b) => {
    const aAvailable = AVAILABLE_COUNTRIES.includes(a.code);
    const bAvailable = AVAILABLE_COUNTRIES.includes(b.code);
    
    if (aAvailable === bAvailable) {
      // If both available or both unavailable, sort alphabetically
      return a.name.localeCompare(b.name);
    }
    // Put available countries first
    return aAvailable ? -1 : 1;
  });

  return (
    <div className="mb-12">
      <h2 className="text-2xl font-bold mb-6 text-center text-[#2F3437]">Popular Destinations</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {sortedCountries.map((country) => {
          const isAvailable = AVAILABLE_COUNTRIES.includes(country.code);
          
          return (
            <div key={country.code} className="relative overflow-hidden">
              <button
                data-country-code={country.code}
                className={`w-full flex items-center justify-between p-4 bg-white border rounded-lg transition duration-300 ease-in-out ${
                  selectedCountry && selectedCountry.code === country.code
                    ? 'border-[#FF6B35] shadow-md'
                    : 'border-gray-200 shadow-sm hover:bg-gray-50'
                } ${!isAvailable ? 'opacity-90' : ''}`}
                onClick={() => handleSelect(country)}
              >
                <div className="flex items-center">
                  <Flag code={country.code} className="w-8 h-6 mr-3 object-cover" />
                  <span className="text-sm font-medium text-[#2F3437]">{country.name}</span>
                </div>
                <ChevronDown className="h-4 w-4 text-gray-400" />
              </button>
              
              {/* Coming Soon Banner */}
              {!isAvailable && (
                <div className="absolute top-0 right-0 transform translate-x-[30%] translate-y-[-30%] rotate-45">
                  <div className="bg-[#2F3437] text-white px-8 py-1 text-xs font-medium whitespace-nowrap shadow-sm">
                    Coming Soon
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
      
      {isModalOpen && selectedCountry && (
        <PackageDropdown 
          packages={packages[selectedCountry.code] || []} 
          selectedCountry={selectedCountry}
          onClose={closeModal}
        />
      )}
    </div>
  );
};

export default CountrySelector;