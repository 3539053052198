// src/components/events.js

export const events = [
  {
    id: 1,
    name: "St. Patrick's Day Festival",
    location: "Dublin, Ireland",
    countryCode: "IE",
    date: "March 17, 2025",
    image: "/images/st-patricks.JPG",
    price: 20,
    available: false,
    description: "Ireland's biggest cultural celebration featuring parades, music, and festivities.",
    longDescription: `St. Patrick's Day in Dublin is more than just a parade - it's a citywide 
    celebration of Irish culture and heritage. The festival transforms Dublin into a vibrant showcase 
    of Irish music, dance, art, and traditions. While the parade is the centerpiece, the entire city 
    comes alive with street performances, cultural exhibitions, and community events.`,
    culturalSignificance: `This celebration began as a religious feast day in the 17th century and has 
    evolved into a global celebration of Irish culture. The holiday marks the arrival of Christianity 
    in Ireland and celebrates the heritage and culture of the Irish people.`
  },
  {
    id: 2,
    name: "Running of the Bulls - San Fermín Festival",
    location: "Pamplona, Spain",
    countryCode: "ES",
    date: "July 6-14, 2025",
    image: "/images/san-fermin.jpg",
    price: 20,
    available: false,
    description: "A historic festival featuring the famous bull runs through Pamplona's streets.",
    longDescription: `The San Fermín Festival, known worldwide for the Running of the Bulls, is a 
    week-long celebration of Basque culture and tradition. While the daily encierro (bull run) draws 
    international attention, the festival is rich with cultural events, religious ceremonies, and 
    traditional celebrations that showcase Pamplona's heritage.`,
    culturalSignificance: `Dating back to medieval times, this festival honors Saint Fermín, 
    Pamplona's first bishop. The running of the bulls began as a practical way of transporting bulls 
    from the corrals to the bullring, eventually becoming an iconic tradition.`
  },
  {
    id: 3,
    name: "Carnival of Venice",
    location: "Venice, Italy",
    countryCode: "IT",
    date: "February 1-12, 2025",
    image: "/images/venice-carnival.JPG",
    price: 20,
    available: false,
    description: "Step into a world of mystery at the most elegant carnival celebration.",
    longDescription: `The Carnival of Venice transforms the floating city into a living theater, 
    where reality and fantasy intertwine. This centuries-old festival fills Venice's canals and 
    campos with elaborate masks, stunning costumes, and an atmosphere of enchanting mystery. The 
    carnival traditionally marked the period of indulgence before Lent, where social hierarchies 
    dissolved behind the anonymity of masks.`,
    culturalSignificance: `Dating back to the 12th century, the Carnival of Venice represented a 
    time when strict social hierarchies could be forgotten, as nobles and common people mingled 
    anonymously behind masks. The tradition of Venetian masks emerged from this period, evolving 
    into an art form that continues to this day. The carnival was banned in 1797 under Austrian 
    rule but was gloriously revived in 1979, becoming a symbol of Venice's cultural heritage.`
  },
  {
    id: 4,
    name: "Oktoberfest Munich",
    location: "Munich, Germany",
    countryCode: "DE",
    date: "September 21 - October 6, 2025",
    image: "/images/oktoberfest.JPG",
    price: 20,
    available: false,
    description: "The world's largest folk festival celebrating Bavarian culture and beer.",
    longDescription: `Oktoberfest is much more than just a beer festival - it's a celebration of 
    Bavarian culture, tradition, and community. The festival grounds, known as the Theresienwiese, 
    transform into a small city of grand beer tents, traditional food stalls, carnival rides, and 
    cultural exhibitions. Each tent has its own unique atmosphere, offering different types of music, 
    food specialties, and local breweries.`,
    culturalSignificance: `Oktoberfest began in 1810 as a wedding celebration for Crown Prince 
    Ludwig of Bavaria and Princess Therese. The festivities were so popular that they became an 
    annual tradition. Today, it represents the heart of Bavarian culture, showcasing traditional 
    clothing (dirndls and lederhosen), music, food, and the region's famous brewing heritage. The 
    festival maintains strict standards, serving only beer brewed within Munich's city limits 
    according to the Reinheitsgebot (German Beer Purity Law).`
  },
  {
    id: 5,
    name: "Cherry Blossom Festival",
    location: "Tokyo, Japan",
    countryCode: "JP",
    date: "March 20 - April 5, 2025",
    image: "/images/sakura.JPG",
    price: 20,
    available: false,
    description: "Experience the magical bloom of cherry blossoms in Japan's most beautiful locations.",
    longDescription: `Hanami, the Japanese tradition of flower viewing, transforms the country into 
    a stunning display of pink and white blossoms. This natural phenomenon sweeps across Japan in a 
    'sakura wave', starting in the warmer south and moving northward. Cities and parks come alive 
    with festivals, special illuminations, and hanami parties, where people gather under the blooming 
    trees to celebrate the ephemeral beauty of the sakura.`,
    culturalSignificance: `Cherry blossoms hold deep cultural significance in Japan, symbolizing 
    both the beauty and transience of life. This concept, known as 'mono no aware', reflects the 
    bittersweet awareness of life's impermanence. The tradition of hanami dates back to the Heian 
    period (794-1185), when aristocrats would gather to view blossoms, write poetry, and contemplate 
    life's fleeting nature. Today, it remains a beloved national celebration that brings communities 
    together.`
  },
  {
    id: 6,
    name: "Día de los Muertos",
    location: "Mexico City, Mexico",
    countryCode: "MX",
    date: "October 31 - November 2, 2025",
    image: "/images/dia-muertos.JPG",
    price: 20,
    available: false,
    description: "Experience Mexico's most colorful and profound celebration of life and death.",
    longDescription: `Día de los Muertos is a UNESCO-recognized celebration that beautifully blends 
    pre-Hispanic traditions with Catholic influences. During these days, Mexicans believe the veil 
    between the world of the living and the dead becomes thin, allowing departed loved ones to 
    return and celebrate with their families. The holiday is marked by colorful ofrendas (altars), 
    elaborate face painting, and a joyful celebration of life and memory.`,
    culturalSignificance: `This tradition represents the Mexican view of death not as something 
    to be feared, but as a natural part of life to be celebrated. The holiday originated with 
    indigenous observations of the harvest season, when the Aztecs honored their deceased loved 
    ones. After the Spanish conquest, these traditions merged with Catholic All Saints' and All 
    Souls' Days, creating the unique celebration we see today. Each element of the celebration - 
    from marigold flowers to papel picado - carries deep symbolic meaning.`
  }
];