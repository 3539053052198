import React, { useState } from 'react';
import { Html } from '@react-three/drei';

const Marker = ({ position, destination, onClick }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <group 
      position={position} 
      onClick={(e) => { 
        e.stopPropagation(); 
        onClick(destination);
      }}
    >
      {/* Pin Stem */}
      <mesh
        onPointerOver={(e) => { 
          e.stopPropagation(); 
          setHovered(true); 
        }}
        onPointerOut={(e) => { 
          e.stopPropagation(); 
          setHovered(false); 
        }}
        position={[0, 0.1, 0]}
      >
        <cylinderGeometry args={[0.01, 0.001, 0.15, 16]} />
        <meshStandardMaterial 
          color="#FF6B35"
          emissive="#FF6B35"
          emissiveIntensity={hovered ? 0.5 : 0}
        />
      </mesh>

      {/* Pin Head */}
      <mesh position={[0, 0.2, 0]}>
        <sphereGeometry args={[0.05, 32, 32]} />
        <meshStandardMaterial 
          color="#FF6B35"
          emissive="#FF6B35"
          emissiveIntensity={hovered ? 0.5 : 0}
        />
      </mesh>

     
    </group>
  );
};

export default Marker;