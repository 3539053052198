// src/components/layout/Header/index.js
import React, { useState, useEffect } from 'react';
import './styles.css'; 

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [navbarTop, setNavbarTop] = useState('20px');

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // Scroll to section function
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      setIsMobileMenuOpen(false);
      const headerHeight = 100;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  };

  // Hide and show the navbar on scroll
  useEffect(() => {
    let lastScrollTop = 0;
    const NAVBAR_HIDDEN_POSITION = '-100px';
    const NAVBAR_VISIBLE_POSITION = '20px';

    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (window.innerWidth > 768) {
        if (scrollTop > lastScrollTop && scrollTop > 100) {
          setNavbarTop(NAVBAR_HIDDEN_POSITION);
        } else {
          setNavbarTop(NAVBAR_VISIBLE_POSITION);
        }
      }
      lastScrollTop = scrollTop;
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Set initial navbar position based on screen size
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setNavbarTop('0');
      } else {
        setNavbarTop('20px');
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <nav
      id="navbar"
      className={`fixed w-full max-w-7xl left-1/2 transform -translate-x-1/2 bg-white bg-opacity-80 backdrop-blur-md shadow z-50 ${
        isMobileMenuOpen ? 'navbar-open' : ''
      } ${window.innerWidth > 768 ? 'rounded-full' : ''}`}
      style={{ 
        top: navbarTop,
        margin: window.innerWidth <= 768 ? 0 : undefined,
        padding: window.innerWidth <= 768 ? '0 1rem' : '0.5rem'
      }}
    >
      <div className="flex items-center justify-between h-14 md:h-16">
        {/* Logo */}
        <div className="flex-shrink-0">
          <img
            className="h-10 md:h-12 w-auto"
            src="images/travelsquadlogo.svg"
            alt="Logo"
            onClick={() => scrollToSection('hero')}
            style={{ cursor: 'pointer' }}
          />
        </div>
        {/* Navigation Links */}
    <div className="hidden md:block">
        <div className="ml-10 flex items-baseline space-x-6">
          <button 
            onClick={() => scrollToSection('about')}
            className="text-gray-800 hover:text-gray-500 px-4 py-2 rounded-full text-lg font-medium"
          >
            About
          </button>
          <button 
            onClick={() => scrollToSection('events')}
            className="text-gray-800 hover:text-gray-500 px-4 py-2 rounded-full text-lg font-medium"
          >
            Events
          </button>
          <button 
            onClick={() => scrollToSection('trips')}
            className="text-gray-800 hover:text-gray-500 px-4 py-2 rounded-full text-lg font-medium"
          >
            Trips
          </button>
          <button
            onClick={() => scrollToSection('custom-trip')}
            className="bg-[#FF6B35] text-white px-8 py-4 rounded-full hover:bg-[#FF8C35]"
          >
            Custom Trip
          </button>
          <button 
            onClick={() => scrollToSection('contact')}
            className="text-gray-800 hover:text-gray-500 px-4 py-2 rounded-full text-lg font-medium"
          >
            Contact us
          </button>
        </div>
      </div>


        {/* Mobile Menu Button */}
        <div className="md:hidden">
          <button
            onClick={toggleMobileMenu}
            className="p-2 rounded-md text-gray-600 hover:text-gray-900 hover:bg-gray-100"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            {isMobileMenuOpen ? (
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            ) : (
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            )}
          </button>
        </div>
      </div>
     {/* Mobile Menu Dropdown */}
     {isMobileMenuOpen && (
  <div className="absolute top-full left-0 right-0 md:hidden bg-white shadow-lg rounded-b-lg py-2 z-50">
    <div className="px-4 pt-2 pb-3 space-y-1">
      <button
        onClick={() => {
          scrollToSection('about');
          setIsMobileMenuOpen(false);
        }}
        className="block w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
      >
        About
      </button>
      <button
        onClick={() => {
          scrollToSection('events');
          setIsMobileMenuOpen(false);
        }}
        className="block w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
      >
        Events
      </button>
      <button
        onClick={() => {
          scrollToSection('trips');
          setIsMobileMenuOpen(false);
        }}
        className="block w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
      >
        Trips
      </button>
      <button
        onClick={() => {
          scrollToSection('custom-trip');
          setIsMobileMenuOpen(false);
        }}
        className="block w-full text-left px-3 py-2 rounded-md text-base font-medium bg-[#FF6B35] text-white hover:bg-[#FF8C35]"
      >
        Custom Trip
      </button>
      <button
        onClick={() => {
          scrollToSection('contact');
          setIsMobileMenuOpen(false);
        }}
        className="block w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
      >
        Contact us
      </button>
    </div>
  </div>
)}
    </nav>
  );
};

export default Header;