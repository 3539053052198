import React, { useState, useEffect } from 'react';
import { Calendar, MapPin, ChevronLeft, ChevronRight, X } from 'lucide-react';
import { Card } from '../../ui/card';
import { events } from "../../../data/events";

// Event Card Component
const EventCard = ({ event, onClick }) => (
  <Card 
    className="relative overflow-hidden cursor-pointer transition-transform duration-300 hover:scale-105"
    onClick={onClick}
  >
    <div className="aspect-square">
      <img
        src={event.image}
        alt={event.name}
        className="w-full h-full object-cover"
      />
      
      <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/80 to-transparent p-4">
        <h3 className="text-lg font-bold text-white mb-1">{event.name}</h3>
        <div className="flex justify-between items-center">
          <div className="flex items-center text-white/90 text-sm">
            <Calendar className="w-4 h-4 mr-1" />
            <span>{event.date}</span>
          </div>
          <div className="flex items-center text-white/90 text-sm">
            <MapPin className="w-4 h-4 mr-1" />
            <span>{event.location}</span>
          </div>
        </div>
      </div>
    </div>
  </Card>
);

// Navigation Button Component
const NavigationButton = ({ onClick, direction, disabled }) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`absolute top-1/2 transform -translate-y-1/2 ${
      direction === 'left' ? '-left-4' : '-right-4'
    } bg-white p-2 rounded-full shadow-lg z-10 transition-all ${
      disabled ? 'opacity-50 cursor-not-allowed' : 'hover:scale-110 hover:bg-gray-50'
    }`}
  >
    {direction === 'left' ? (
      <ChevronLeft className="w-6 h-6 text-gray-600" />
    ) : (
      <ChevronRight className="w-6 h-6 text-gray-600" />
    )}
  </button>
);

// Event Modal Component
const EventModal = ({ event, onClose }) => {
    useEffect(() => {
      const originalStyle = window.getComputedStyle(document.body).overflow;
      document.body.style.overflow = 'hidden';
      return () => {
        document.body.style.overflow = originalStyle;
      };
    }, []);
  
    if (!event) return null;
  
    return (
      <div 
        className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-start z-50 overflow-y-auto"
        onClick={(e) => {
          if (e.target === e.currentTarget) onClose();
        }}
      >
        <div className="relative bg-white rounded-lg w-full max-w-2xl my-8 mx-4">
          <div className="relative h-64">
            <img
              src={event.image}
              alt={event.name}
              className="w-full h-full object-cover rounded-t-lg"
            />
            <button
              onClick={onClose}
              className="absolute top-4 right-4 bg-black/50 p-2 rounded-full text-white hover:bg-black/70 transition-colors"
            >
              <X className="w-5 h-5" />
            </button>
            {!event.available && (
              <div className="absolute top-4 left-4 bg-white text-[#FF6B35] px-4 py-1 rounded-full text-sm font-medium">
                Coming Soon
              </div>
            )}
          </div>
          
          <div className="p-6">
            <div className="flex justify-between items-start mb-4">
              <div>
                <h2 className="text-2xl font-bold mb-2">{event.name}</h2>
                <div className="flex items-center text-gray-600 mb-1">
                  <MapPin className="w-4 h-4 mr-2" />
                  <span>{event.location}</span>
                </div>
                <div className="flex items-center text-gray-600">
                  <Calendar className="w-4 h-4 mr-2" />
                  <span>{event.date}</span>
                </div>
              </div>
              <div className="text-2xl font-bold text-[#FF6B35]">
                ${event.price}
              </div>
            </div>
  
            <div className="prose max-w-none">
              <p className="text-gray-600 mb-6">{event.longDescription}</p>
              
              <h3 className="text-lg font-semibold mb-3">Cultural Significance</h3>
              <p className="text-gray-600 mb-6">{event.culturalSignificance}</p>
            </div>
  
            <button 
              className={`w-full mt-6 bg-[#FF6B35] text-white px-6 py-3 rounded-full hover:bg-[#FF8C35] 
                transition-colors flex items-center justify-center gap-2 ${
                !event.available ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={!event.available}
            >
              Buy Specialized Event Plan
            </button>
          </div>
        </div>
      </div>
    );
  };

// Main Component
const FeaturedEvents = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const eventsPerPage = window.innerWidth >= 768 ? 4 : 1;
  const totalPages = Math.ceil(events.length / eventsPerPage);

  const currentEvents = events.slice(
    currentPage * eventsPerPage,
    (currentPage + 1) * eventsPerPage
  );

  const nextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(prev => prev + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(prev => prev - 1);
    }
  };

  return (
    <section id="events" className="py-16 bg-[#F7F7F7]">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-4">Upcoming Cultural Events</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Experience the world's most celebrated festivals and cultural events.
          </p>
        </div>

        <div className="relative max-w-7xl mx-auto px-8">
          <NavigationButton 
            direction="left" 
            onClick={prevPage} 
            disabled={currentPage === 0}
          />
          
          <div className="grid grid-cols-1 md:grid-cols-4 gap-6 overflow-hidden">
            {currentEvents.map((event) => (
              <div key={event.id} className={`${window.innerWidth < 768 ? 'col-span-1 mx-auto w-full max-w-md' : ''}`}>
                <EventCard 
                  event={event}
                  onClick={() => setSelectedEvent(event)}
                />
              </div>
            ))}
          </div>

          <NavigationButton 
            direction="right" 
            onClick={nextPage} 
            disabled={currentPage === totalPages - 1}
          />
        </div>

        <div className="flex justify-center mt-8 space-x-2">
          {[...Array(totalPages)].map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentPage(index)}
              className={`w-2 h-2 rounded-full transition-all ${
                currentPage === index 
                  ? 'bg-[#FF6B35] w-4' 
                  : 'bg-gray-300 hover:bg-gray-400'
              }`}
              aria-label={`Go to page ${index + 1}`}
            />
          ))}
        </div>
      </div>

      {selectedEvent && (
        <EventModal 
          event={selectedEvent} 
          onClose={() => setSelectedEvent(null)} 
        />
      )}
    </section>
  );
};

export default FeaturedEvents;