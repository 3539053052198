import React, { useState, useEffect } from 'react';

const DynamicHero = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  // Load environment variables
  const GOOGLE_FORM_ACTION_URL = import.meta.env.VITE_GOOGLE_FORM_ACTION_URL;
  const EMAIL_ENTRY_ID = import.meta.env.VITE_EMAIL_ENTRY_ID;
  
  const images = [
    '/images/hero1.jpg',
    '/images/hero2.jpg',
    '/images/hero3.jpg'
  ];
  
  const headlines = [
    "Discover Your Next Adventure",
    "Experience the World's Wonders",
    "Create Unforgettable Memories"
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [images.length]);

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
  
    try {
      const formData = new FormData();
      formData.append(EMAIL_ENTRY_ID, email);
  
      await fetch(GOOGLE_FORM_ACTION_URL, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(formData).toString()
      });
  
      // Show success message
      setEmail('');
      setStatus('Thanks for joining our community! 🎉');
      
    } catch (error) {
      console.error('Error:', error);
      setStatus('Something went wrong. Please try again.');
    } finally {
      setIsSubmitting(false);
      setTimeout(() => setStatus(''), 5000);
    }
  };

  return (
    <section id="hero" className="relative h-screen overflow-hidden">
      {images.map((image, index) => (
        <div
          key={index}
          className={`absolute inset-0 bg-cover bg-center transition-opacity duration-1000 ${
            index === currentImageIndex ? 'opacity-100' : 'opacity-0'
          }`}
          style={{ backgroundImage: `url(${image})` }}
        />
      ))}
      <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <div className="text-center px-4 max-w-3xl">
          <h1 className="text-4xl md:text-6xl font-bold text-white mb-4 transition-all duration-500">
            {headlines[currentImageIndex]}
          </h1>
          <p className="text-xl text-white mb-8">
            
          </p>
          
          <form onSubmit={handleEmailSubmit} className="flex flex-col sm:flex-row gap-2 max-w-md mx-auto mb-6">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
              className="flex-grow px-4 py-3 rounded-full border border-white/20 bg-white/30 text-white placeholder-white/90 focus:outline-none focus:border-white"
              disabled={isSubmitting}
            />
            <button 
              type="submit"
              className="bg-[#FF6B35] text-white px-8 py-3 rounded-full hover:bg-[#FF8C35] transition duration-300 transform hover:scale-105 disabled:opacity-50 disabled:hover:scale-100 whitespace-nowrap"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <span className="flex items-center">
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Joining...
                </span>
              ) : 'Join Community'}
            </button>
          </form>
          
          {status && (
            <div className="mt-2 animate-fade-in">
              <p className="text-white text-sm bg-black/30 p-2 rounded-full inline-block">
                {status}
              </p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default DynamicHero;