// src/components/AugmentedRealityPreview.jsx
import React from 'react';
import { Camera } from 'lucide-react';

const AugmentedRealityPreview = () => (
  <section className="py-16 bg-[#F7F7F7]">
    <div className="container mx-auto text-center">
      <h2 className="text-3xl font-bold mb-2">Experience Destinations in AR</h2>
      <h4 className="text-2xl font-bold mb-8 text-center">There's believing, and then there's Seeing</h4>
      <div className="relative w-full max-w-3xl mx-auto h-[50vh] bg-[url('/images/ar-preview.jpg')] bg-cover bg-center rounded-lg overflow-hidden">
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
           <a
            href="https://www.google.com/maps/@41.8891977,12.4925147,3a,75y,352.38h,95.65t/data=!3m7!1e1!3m5!1s8tjXxOETZq8Q394idE6voQ!2e0!6shttps:%2F%2Fstreetviewpixels-pa.googleapis.com%2Fv1%2Fthumbnail%3Fcb_client%3Dmaps_sv.tactile%26w%3D900%26h%3D600%26pitch%3D-5.649116699843717%26panoid%3D8tjXxOETZq8Q394idE6voQ%26yaw%3D352.377992853602!7i16384!8i8192?coh=205410&entry=ttu&g_ep=EgoyMDI0MTAwOS4wIKXMDSoASAFQAw%3D%3D"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="bg-[#FF6B35] text-white px-6 py-3 rounded-full hover:bg-[#FF8C35] transition duration-300 transform hover:scale-105 flex items-center">
              <Camera className="mr-2" />
              Launch AR Experience
            </button>
          </a>
        </div>
      </div>
      <p className="mt-4 text-gray-600">Our augmented reality department is still under developement. In the meantime enjoy street veiw :)</p>
    </div>
  </section>
);

export default AugmentedRealityPreview;
