import React, { useState } from 'react';
import { Button } from "../../ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "../../ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/tabs";
import { Calendar, DollarSign, Users, MapPin, Clock, Camera, X, Coins } from 'lucide-react';
import Flag from 'react-world-flags';




const TripOptionCard = ({ option, onSelect }) => (
  <Card className="mb-4 hover:shadow-lg transition-shadow duration-300">
    <CardHeader>
      <CardTitle className="flex justify-between items-center">
        <span>{option.duration}-Day Trip</span>
        <span className="text-xl font-bold">${option.price}</span>
      </CardTitle>
    </CardHeader>
    <CardContent>
      <ul className="list-disc list-inside">
        {option.highlights.map((highlight, index) => (
          <li key={index} className="text-sm mb-1">{highlight}</li>
        ))}
      </ul>
      <Button className="w-full mt-4" onClick={() => onSelect(option)}>Select Package</Button>
    </CardContent>
  </Card>
);

const PackageDropdown = ({ packages, selectedCountry, onClose }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  if (!packages || packages.length === 0) {
    return null;
  }

  const handlePayment = () => {
    if (selectedOption && selectedOption.stripeLink) {
      window.location.href = selectedOption.stripeLink;
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4 z-50">
      <Card className="w-full max-w-4xl max-h-[90vh] overflow-y-auto relative">
        <Button 
          variant="ghost" 
          size="icon" 
          onClick={onClose} 
          className="absolute top-2 right-2 z-10"
        >
          <X size={24} />
        </Button>
        <CardHeader className="pt-8 text-center">
          <CardTitle className="flex items-center justify-center text-2xl">
            <Flag code={selectedCountry.code} className="w-8 h-6 mr-3 object-cover" />
            <span>{selectedCountry.name}</span>
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mb-6">
            <div className="flex items-center">
              <MapPin size={16} className="mr-2 text-gray-500" />
              <span><strong>Capital:</strong> {selectedCountry.capital}</span>
            </div>
            <div className="flex items-center">
              <Users size={16} className="mr-2 text-gray-500" />
              <span><strong>Language:</strong> {selectedCountry.language}</span>
            </div>
            <div className="flex items-center">
              <Coins size={16} className="mr-2 text-gray-500" />
              <span><strong>Currency:</strong> {selectedCountry.currency}</span>
            </div>
            <div className="flex items-center">
              <Calendar size={16} className="mr-2 text-gray-500" />
              <span><strong>Best Time:</strong> {selectedCountry.bestTimeToVisit}</span>
            </div>
            <div className="flex items-center">
              <Clock size={16} className="mr-2 text-gray-500" />
              <span><strong>Time Zone:</strong> {selectedCountry.timeZone}</span>
            </div>
          </div>
          <div className="mb-6">
            <h3 className="font-semibold mb-2 flex items-center">
              <Camera size={16} className="mr-2 text-gray-500" /> Top Attractions:
            </h3>
            <ul className="list-disc list-inside pl-5">
              {selectedCountry.attractions.map((attraction, index) => (
                <li key={index} className="mb-1">{attraction}</li>
              ))}
            </ul>
          </div>
          <Tabs defaultValue={packages[0].duration.toString()}>
            <TabsList className="grid w-full grid-cols-3">
              {packages.map((pkg) => (
                <TabsTrigger key={pkg.id} value={pkg.duration.toString()}>{pkg.duration} Days</TabsTrigger>
              ))}
            </TabsList>
            {packages.map((pkg) => (
              <TabsContent key={pkg.id} value={pkg.duration.toString()}>
                <TripOptionCard 
                  option={pkg}
                  onSelect={setSelectedOption} 
                />
              </TabsContent>
            ))}
          </Tabs>
          {selectedOption && (
            <div className="mt-4 p-4 bg-green-100 rounded-lg">
              <p className="font-semibold">
                You've selected the {selectedOption.duration}-day itinerary for ${selectedOption.price}.
              </p>
              <p>Click 'Confirm Booking' to proceed with your reservation.</p>
              <Button 
                className="mt-2"
                onClick={handlePayment}
              >
                Confirm Booking
              </Button>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default PackageDropdown;