import React, { Suspense, useRef, useState, useMemo, useEffect, useCallback } from 'react';
import { Canvas, useThree, useFrame } from '@react-three/fiber';
import { OrbitControls, Stars, Html } from '@react-three/drei';
import * as THREE from 'three';
import { X, MapPin } from 'lucide-react';
import Marker from './Marker';

// Globe component with destinations as prop
const Globe = ({ destinations, onMarkerClick }) => {
  const groupRef = useRef();
  const sphereRef = useRef();
  const { camera, raycaster, mouse } = useThree();
  
  // Create texture loader instance
  const textureLoader = useMemo(() => new THREE.TextureLoader(), []);
  const [textures, setTextures] = useState(null);
  const [loadError, setLoadError] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [prevMousePosition, setPrevMousePosition] = useState({ x: 0, y: 0 });

  // Load textures
  useEffect(() => {
    const loadTextures = async () => {
      try {
        const [colorMap, bumpMap, specMap] = await Promise.all([
          new Promise((resolve, reject) => 
            textureLoader.load('/images/assets/earth_color.jpg', resolve, undefined, reject)
          ),
          new Promise((resolve, reject) => 
            textureLoader.load('/images/assets/earth_bump.jpg', resolve, undefined, reject)
          ),
          new Promise((resolve, reject) => 
            textureLoader.load('/images/assets/earth_specular.jpg', resolve, undefined, reject)
          ),
        ]);

        setTextures({ colorMap, bumpMap, specMap });
      } catch (error) {
        console.error('Error loading textures:', error);
        setLoadError('Failed to load globe textures');
      }
    };

    loadTextures();
  }, [textureLoader]);

  // Check if mouse is over the globe
  const checkGlobeHover = useCallback((event) => {
    if (!sphereRef.current) return false;
  
    // For Three.js pointer events, we need to use the event directly
    if (event.eventObject) {
      return event.eventObject === sphereRef.current;
    }
  
    // For DOM events (like wheel events), we use the original approach
    const canvas = event.target.closest('canvas');
    if (!canvas) return false;
    
    const rect = canvas.getBoundingClientRect();
    const x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    const y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
    
    raycaster.setFromCamera({ x, y }, camera);
    const intersects = raycaster.intersectObject(sphereRef.current);
    return intersects.length > 0;
  }, [camera, raycaster]);

  // Handle wheel zoom
  const handleWheel = useCallback((event) => {
    const isOverGlobe = checkGlobeHover(event);
    
    if (isOverGlobe) {
      event.preventDefault();
      event.stopPropagation();
      
      const delta = event.deltaY;
      const scale = groupRef.current.scale.x;
      const newScale = Math.max(0.5, Math.min(2, scale + delta * -0.001));
      
      groupRef.current.scale.set(newScale, newScale, newScale);
    }
  }, [checkGlobeHover]);

  // Add wheel event listener
  useEffect(() => {
    const canvas = document.querySelector('canvas');
    if (!canvas) return;

    const wheelListener = (e) => {
      handleWheel(e);
    };

    canvas.addEventListener('wheel', wheelListener, { passive: false });
    
    return () => {
      canvas.removeEventListener('wheel', wheelListener);
    };
  }, [handleWheel]);

  // Handle dragging
  useFrame(() => {
    if (isDragging && groupRef.current) {
      const { x, y } = prevMousePosition;
      const deltaX = (x - (prevMousePosition.x || x)) * 0.01;
      const deltaY = (y - (prevMousePosition.y || y)) * 0.01;
      groupRef.current.rotation.y += deltaX;
      groupRef.current.rotation.x += deltaY;
    }
  });

  if (loadError) {
    return (
      <Html center>
        <div className="text-white text-center">
          <p>{loadError}</p>
          <button 
            className="mt-4 bg-[#FF6B35] text-white px-4 py-2 rounded-full"
            onClick={() => window.location.reload()}
          >
            Reload
          </button>
        </div>
      </Html>
    );
  }

  if (!textures) {
    return (
      <Html center>
        <div className="text-white">Loading globe textures...</div>
      </Html>
    );
  }

  return (
    <group
    ref={groupRef}
    scale={[1, 1, 1]}
    onPointerEnter={(e) => {
      e.stopPropagation();
      setIsHovered(checkGlobeHover(e));
    }}
    onPointerLeave={() => {
      setIsHovered(false);
    }}
    onPointerDown={(e) => {
      e.stopPropagation();
      if (checkGlobeHover(e)) {
        setIsDragging(true);
        setPrevMousePosition({ x: e.clientX, y: e.clientY });
      }
    }}
    onPointerUp={() => {
      setIsDragging(false);
    }}
    onPointerMove={(e) => {
      if (isDragging) {
        setPrevMousePosition({ x: e.clientX, y: e.clientY });
      }
    }}
  >
      <mesh ref={sphereRef}>
        <sphereGeometry args={[2, 64, 64]} />
        <meshPhongMaterial
          map={textures.colorMap}
          bumpMap={textures.bumpMap}
          bumpScale={0.05}
          specularMap={textures.specMap}
          specular={new THREE.Color('white')}
          shininess={50}
          emissive={new THREE.Color(0x222222)}
          emissiveIntensity={0.5}
        />
      </mesh>
      {destinations.map((dest, index) => (
        <Marker
          key={index}
          position={[
            -(2 * Math.sin((90 - dest.coordinates[1]) * (Math.PI / 180)) * Math.cos((dest.coordinates[0] + 180) * (Math.PI / 180))),
            2 * Math.cos((90 - dest.coordinates[1]) * (Math.PI / 180)),
            2 * Math.sin((90 - dest.coordinates[1]) * (Math.PI / 180)) * Math.sin((dest.coordinates[0] + 180) * (Math.PI / 180))
          ]}
          destination={dest}
          onClick={onMarkerClick}
        />
      ))}
    </group>
  );
};

// Main InteractiveGlobe component
const InteractiveGlobe = () => {
  const destinations = useMemo(() => [
    { name: 'Austria', code: 'AT', coordinates: [16.3738, 48.2082], cities: ['Vienna', 'Salzburg', 'Innsbruck', 'Hallstatt'] },
    { name: 'Canada', code: 'CA', coordinates: [-106.3468, 56.1304], cities: ['Toronto', 'Vancouver', 'Montreal', 'Banff'] },
    { name: 'Colombia', code: 'CO', coordinates: [-74.0721, 4.7110], cities: ['Bogota', 'Cartagena', 'Medellin', 'Santa Marta'] },
    { name: 'Croatia', code: 'HR', coordinates: [15.9819, 45.8150], cities: ['Dubrovnik', 'Split', 'Zagreb', 'Hvar'] },
    { name: 'Cyprus', code: 'CY', coordinates: [33.4229, 35.1264], cities: ['Nicosia', 'Limassol', 'Paphos', 'Larnaca'] },
    { name: 'Czech Republic', code: 'CZ', coordinates: [14.4378, 50.0755], cities: ['Prague', 'Brno', 'Český Krumlov', 'Karlovy Vary'] },
    { name: 'England', code: 'GB-ENG', coordinates: [-0.1276, 51.5074], cities: ['London', 'Manchester', 'Liverpool', 'Bath'] },
    { name: 'France', code: 'FR', coordinates: [2.3522, 48.8566], cities: ['Paris', 'Nice', 'Lyon', 'Mont Saint-Michel'] },
    { name: 'Germany', code: 'DE', coordinates: [13.4050, 52.5200], cities: ['Berlin', 'Munich', 'Hamburg', 'Frankfurt'] },
    { name: 'Greece', code: 'GR', coordinates: [23.7275, 37.9838], cities: ['Athens', 'Santorini', 'Mykonos', 'Rhodes'] },
    { name: 'Iceland', code: 'IS', coordinates: [-21.9426, 64.1466], cities: ['Reykjavik', 'Akureyri', 'Vik', 'Blue Lagoon'] },
    { name: 'Indonesia', code: 'ID', coordinates: [115.1889, -8.4095], cities: ['Bali', 'Jakarta', 'Yogyakarta', 'Lombok'] },
    { name: 'Ireland', code: 'IE', coordinates: [-6.2603, 53.3498], cities: ['Dublin', 'Cork', 'Galway', 'Cliffs of Moher'] },
    { name: 'Israel', code: 'IL', coordinates: [35.2137, 31.7683], cities: ['Jerusalem', 'Tel Aviv', 'Haifa', 'Eilat'] },
    { name: 'Italy', code: 'IT', coordinates: [12.4964, 41.9028], cities: ['Rome', 'Venice', 'Florence', 'Amalfi Coast'] },
    { name: 'Japan', code: 'JP', coordinates: [139.6503, 35.6762], cities: ['Tokyo', 'Kyoto', 'Osaka', 'Mount Fuji'] },
    { name: 'Mexico', code: 'MX', coordinates: [-102.5528, 23.6345], cities: ['Mexico City', 'Cancun', 'Tulum', 'Oaxaca'] },
    { name: 'Morocco', code: 'MA', coordinates: [-6.8498, 33.9716], cities: ['Marrakech', 'Fes', 'Casablanca', 'Chefchaouen'] },
    { name: 'Netherlands', code: 'NL', coordinates: [4.9041, 52.3676], cities: ['Amsterdam', 'Rotterdam', 'The Hague', 'Utrecht'] },
    { name: 'Poland', code: 'PL', coordinates: [21.0122, 52.2297], cities: ['Warsaw', 'Krakow', 'Gdansk', 'Wroclaw'] },
    { name: 'Portugal', code: 'PT', coordinates: [-9.1393, 38.7223], cities: ['Lisbon', 'Porto', 'Algarve', 'Madeira'] },
    { name: 'Romania', code: 'RO', coordinates: [26.1025, 44.4268], cities: ['Bucharest', 'Brasov', 'Sibiu', 'Cluj-Napoca'] },
    { name: 'Scotland', code: 'GB-SCT', coordinates: [-3.4433, 56.3798], cities: ['Edinburgh', 'Glasgow', 'Isle of Skye', 'Loch Ness'] },
    { name: 'South Africa', code: 'ZA', coordinates: [22.9375, -30.5595], cities: ['Cape Town', 'Johannesburg', 'Kruger Park', 'Durban'] },
    { name: 'Spain', code: 'ES', coordinates: [-3.7038, 40.4168], cities: ['Madrid', 'Barcelona', 'Seville', 'Granada'] },
    { name: 'Switzerland', code: 'CH', coordinates: [7.4474, 46.9480], cities: ['Zurich', 'Geneva', 'Lucerne', 'Zermatt'] },
    { name: 'Thailand', code: 'TH', coordinates: [100.5018, 13.7563], cities: ['Bangkok', 'Phuket', 'Chiang Mai', 'Ayutthaya'] },
    { name: 'Turkey', code: 'TR', coordinates: [32.8597, 39.9334], cities: ['Istanbul', 'Cappadocia', 'Antalya', 'Ephesus'] },
    { name: 'United Arab Emirates', code: 'AE', coordinates: [54.3773, 24.4539], cities: ['Dubai', 'Abu Dhabi', 'Sharjah', 'Al Ain'] },
    { name: 'United States', code: 'US', coordinates: [-95.7129, 37.0902], cities: ['New York', 'Los Angeles', 'Las Vegas', 'Miami'] },
    { name: 'Vietnam', code: 'VN', coordinates: [105.8542, 21.0285], cities: ['Hanoi', 'Ho Chi Minh City', 'Ha Long Bay', 'Hoi An'] },
  ], []);

  const [selectedDestination, setSelectedDestination] = useState(null);

  const handleMarkerClick = (destination) => {
    setSelectedDestination(destination);
  };

  const handleClose = () => {
    setSelectedDestination(null);
  };

  const scrollToPackages = () => {
    // Find the trips section
    const tripsSection = document.getElementById('trips');
    if (tripsSection) {
      // Scroll to the trips section
      tripsSection.scrollIntoView({ behavior: 'smooth' });
      
      // Add a slight delay before triggering the click on the country button
      setTimeout(() => {
        // Find and click the button for the selected country
        const countryButton = document.querySelector(`button[data-country-code="${selectedDestination.code}"]`);
        if (countryButton) {
          countryButton.click();
        }
      }, 1000);
    }
    // Close the popup after initiating the scroll
    setSelectedDestination(null);
  };

  return (
    <section className="py-16 bg-white">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold mb-2 text-center">Earth</h2>
        <h4 className="text-1xl font-bold mb-8 text-center">
          We look forward to planning trips on other planets as well...... Coming soon :)
        </h4>

        <div 
          className="w-full h-[80vh] bg-gray-900 rounded-lg overflow-hidden relative"
          style={{ touchAction: 'none' }}
        >
          <Canvas 
            camera={{ position: [0, 0, 5], fov: 75 }}
            gl={{ antialias: true, alpha: false }}
          >
            <color attach="background" args={['#111827']} />
            <ambientLight intensity={0.5} />
            <pointLight position={[10, 10, 10]} intensity={2} />
            <pointLight position={[-10, -10, -10]} intensity={1.5} />
            <hemisphereLight skyColor={0xffffff} groundColor={0x444444} intensity={0.6} />
            <Suspense fallback={<Html center><div className="text-white">Loading Globe...</div></Html>}>
              <Globe destinations={destinations} onMarkerClick={handleMarkerClick} />
              <Stars radius={100} depth={50} count={5000} factor={4} saturation={0} fade />
            </Suspense>
            <OrbitControls 
              enableZoom={false}
              enablePan={false}
              enableDamping={true}
              dampingFactor={0.05}
              minPolarAngle={Math.PI / 4}
              maxPolarAngle={Math.PI * 3 / 4}
            />
          </Canvas>
          
          {selectedDestination && (
            <div className="absolute top-8 right-8 bg-white text-black p-6 rounded-lg shadow-xl max-w-sm">
              <button
                onClick={handleClose}
                className="absolute top-2 right-2 p-2 hover:bg-gray-100 rounded-full transition-colors"
              >
                <X className="h-5 w-5 text-gray-500" />
              </button>
              
              <h3 className="text-2xl font-bold mb-4">{selectedDestination.name}</h3>
              
              <div className="mb-6">
                <h4 className="text-lg font-semibold mb-2 flex items-center">
                  <MapPin className="w-5 h-5 mr-2 text-[#FF6B35]" />
                  Major Destinations:
                </h4>
                <ul className="list-disc list-inside space-y-1">
                  {selectedDestination.cities.map((city, index) => (
                    <li key={index} className="text-gray-700">{city}</li>
                  ))}
                </ul>
              </div>

              <button
                onClick={scrollToPackages}
                className="w-full bg-[#FF6B35] text-white px-4 py-3 rounded-full hover:bg-[#FF8C35] transition-colors flex items-center justify-center gap-2"
              >
                <span>View Itineraries</span>
              </button>
            </div>
          )}
          
          <div className="absolute bottom-8 left-8 text-white">
            <h3 className="text-2xl font-bold mb-2">Interactive 3D Globe (still a work in progress...)</h3>
            <p>Click and drag to rotate, hover and scroll to zoom</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InteractiveGlobe;