import React, { useState, useEffect } from 'react';
import { X, Compass, Calendar, Clock, Crown, MapPin, Loader } from 'lucide-react';

const CustomTripSection = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCalendlyLoading, setIsCalendlyLoading] = useState(true);
  const [calendlyLoadError, setCalendlyLoadError] = useState(false);

  // Load Calendly script with proper error handling
  useEffect(() => {
    const loadCalendly = async () => {
      try {
        // Check if Calendly is already loaded
        if (window.Calendly) {
          setIsCalendlyLoading(false);
          return;
        }

        // Create and load script from the new domain
        const script = document.createElement('script');
        script.src = 'https://calendly.com/assets/external/widget.js';
        script.async = true;
        script.crossOrigin = 'anonymous';
        
        // Promise to handle script loading
        const loadPromise = new Promise((resolve, reject) => {
          script.onload = resolve;
          script.onerror = reject;
        });

        document.head.appendChild(script);
        await loadPromise;
        
        // Add Calendly inline widget styles
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = 'https://calendly.com/assets/external/widget.css';
        document.head.appendChild(link);

        // Verify Calendly object exists
        if (!window.Calendly) {
          throw new Error('Calendly failed to initialize');
        }

        setIsCalendlyLoading(false);
      } catch (error) {
        console.error('Error loading Calendly:', error);
        setCalendlyLoadError(true);
        setIsCalendlyLoading(false);
      }
    };

    loadCalendly();

    // Cleanup
    return () => {
      const script = document.querySelector('script[src*="calendly.com/assets/external/widget.js"]');
      const styles = document.querySelector('link[href*="calendly.com/assets/external/widget.css"]');
      if (script) script.remove();
      if (styles) styles.remove();
    };
  }, []);

  // Body scroll lock effect
  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isModalOpen]);

  const openCalendly = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    // Close modal first
    setIsModalOpen(false);
    
    // Handle Calendly opening
    if (isCalendlyLoading) {
      console.warn('Calendly is still loading');
      return;
    }

    if (calendlyLoadError) {
      // Fallback to direct Calendly URL
      window.open('https://calendly.com/t-squad/45min', '_blank');
      return;
    }

    try {
      // Use the newer inline embed API
      if (window.Calendly && typeof window.Calendly.initInlineWidget === 'function') {
        // Create a temporary container for the widget
        const container = document.createElement('div');
        container.style.position = 'fixed';
        container.style.top = '0';
        container.style.left = '0';
        container.style.width = '100%';
        container.style.height = '100%';
        container.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
        container.style.zIndex = '9999';
        
        const widgetContainer = document.createElement('div');
        widgetContainer.style.position = 'relative';
        widgetContainer.style.width = '100%';
        widgetContainer.style.height = '100%';
        widgetContainer.style.maxWidth = '1040px';
        widgetContainer.style.margin = '32px auto';
        widgetContainer.style.background = 'white';
        widgetContainer.style.borderRadius = '8px';
        
        container.appendChild(widgetContainer);
        document.body.appendChild(container);

        // Initialize the widget
        window.Calendly.initInlineWidget({
          url: 'https://calendly.com/t-squad/45min?hide_gdpr_banner=1&primary_color=ff5800',
          parentElement: widgetContainer,
        });

        // Add close button and handling
        const closeBtn = document.createElement('button');
        closeBtn.innerHTML = '×';
        closeBtn.style.position = 'absolute';
        closeBtn.style.top = '8px';
        closeBtn.style.right = '8px';
        closeBtn.style.fontSize = '24px';
        closeBtn.style.background = 'none';
        closeBtn.style.border = 'none';
        closeBtn.style.cursor = 'pointer';
        closeBtn.style.color = '#666';
        closeBtn.onclick = () => {
          document.body.removeChild(container);
        };
        
        widgetContainer.appendChild(closeBtn);

        // Click outside to close
        container.onclick = (e) => {
          if (e.target === container) {
            document.body.removeChild(container);
          }
        };
      } else {
        throw new Error('Calendly widget initialization failed');
      }
    } catch (error) {
      console.error('Error opening Calendly:', error);
      // Fallback to direct URL
      window.open('https://calendly.com/t-squad/45min', '_blank');
    }
  };

  const pricing = [
    { 
      title: 'Basic Package', 
      price: 250, 
      description: "Perfect for weekend getaways and short trips",
      icon: (
        <Crown className="w-8 h-8 text-[#FF6B35]" />
      )
    },
    { 
      title: 'Standard Package', 
      price: 450, 
      description: "Ideal for week-long adventures",
      icon: (
        <div className="flex gap-1">
          <Crown className="w-8 h-8 text-[#FF6B35]" />
          <Crown className="w-8 h-8 text-[#FF6B35]" />
        </div>
      )
    },
    { 
      title: 'Premium Package', 
      price: 950, 
      description: "Best for multi-city or extended journeys",
      icon: (
        <div className="flex gap-1">
          <Crown className="w-8 h-8 text-[#FF6B35]" />
          <Crown className="w-8 h-8 text-[#FF6B35]" />
          <Crown className="w-8 h-8 text-[#FF6B35]" />
        </div>
      )
    },
  ];

  const features = [
    { 
      text: "Flexible scheduling",
      icon: <Calendar className="w-6 h-6 text-[#FF6B35]" />
    },
    { 
      text: "Expert recommendations for hidden gems and local favorites",
      icon: <Compass className="w-6 h-6 text-[#FF6B35]" />
    },
    { 
      text: "Personalized itineraries tailored to your interests",
      icon: <MapPin className="w-6 h-6 text-[#FF6B35]" />
    },
    { 
      text: "Priority support throughout your journey",
      icon: <Clock className="w-6 h-6 text-[#FF6B35]" />
    },
  ];

  const renderScheduleButton = () => (
    <button 
      className={`w-full bg-[#FF6B35] hover:bg-[#FF8C35] text-white px-6 py-3 rounded-full transition-all transform hover:scale-105 font-medium ${
        isCalendlyLoading ? 'opacity-75 cursor-wait' : ''
      }`}
      onClick={openCalendly}
      disabled={isCalendlyLoading}
    >
      {isCalendlyLoading ? (
        <span className="flex items-center justify-center">
          <Loader className="animate-spin -ml-1 mr-3 h-5 w-5" />
          Loading...
        </span>
      ) : (
        'Schedule Consultation'
      )}
    </button>
  );

  return (
    <section id="custom-trip" className="py-24 bg-gradient-to-b from-[#F7F7F7] to-white">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <h2 className="text-4xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-[#FF6B35] to-[#FF8C35] pb-2">
            Custom Trip Planning Services
          </h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
            {features.map((feature, index) => (
              <div key={index} className="flex items-center gap-4 text-left">
                <div className="flex-shrink-0">
                  {feature.icon}
                </div>
                <p className="text-gray-600">{feature.text}</p>
              </div>
            ))}
          </div>
          <button 
            className="mx-auto inline-flex items-center bg-[#FF6B35] hover:bg-[#FF8C35] text-white px-8 py-4 rounded-full transition-all transform hover:scale-105 shadow-lg hover:shadow-xl"
            onClick={() => setIsModalOpen(true)}
          >
            View Planning Options
          </button>
        </div>

        {/* Modal */}
        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-start z-50 p-4">
            <div className="w-full max-w-4xl bg-white rounded-xl shadow-xl relative mt-8 max-h-[90vh] overflow-y-auto">
              <button 
                onClick={() => setIsModalOpen(false)} 
                className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 transition-colors z-10"
              >
                <X size={24} />
              </button>
              
              <div className="p-8">
                <h3 className="text-3xl font-bold text-center mb-8 text-gray-800">
                  Custom Trip Planning Services
                </h3>
                
                <div className="grid md:grid-cols-3 gap-6 mb-12">
                  {pricing.map((plan, index) => (
                    <div 
                      key={index} 
                      className="bg-white rounded-xl shadow-lg p-6 text-center transform transition-all hover:scale-105 hover:shadow-xl border border-gray-100"
                    >
                      <div className="flex justify-center mb-4">
                        {plan.icon}
                      </div>
                      <h4 className="text-xl font-bold mb-2">{plan.title}</h4>
                      <p className="text-4xl font-bold mb-4 text-[#FF6B35]">
                        ${plan.price}
                      </p>
                      <p className="text-gray-600 mb-6">{plan.description}</p>
                      {renderScheduleButton()}
                    </div>
                  ))}
                </div>
                <p className="text-center text-gray-600">
                  Payment is processed after the initial consultation.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default CustomTripSection;