// src/App.jsx
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './components/layout/Header';
import DynamicHero from './components/sections/hero/DynamicHero';
import About from './components/sections/about/About';
import FeaturedEvents from './components/sections/events/FeaturedEvents';
import InteractiveGlobe from './components/sections/globe/InteractiveGlobe';
import PurchasePage from './components/sections/trips/PurchasePage';
import CustomTripSection from './components/sections/custom-trip/CustomTripSection';
import AugmentedRealityPreview from './components/sections/ar-preview/AugmentedRealityPreview';
import Contact from './components/sections/contact/Contact';

const App = () => (
  <Router>
    <div className="min-h-screen bg-gray-100">
      <Header />
      <main>
        <DynamicHero />
        <About />
        <FeaturedEvents />
        <InteractiveGlobe />
        <PurchasePage />
        <CustomTripSection />
        <AugmentedRealityPreview />
        <Contact />
      </main>
      <footer className="bg-[#2F3437] text-white p-4 text-center">
        © {new Date().getFullYear()} TravelSquad.LLC All rights reserved.
      </footer>
    </div>
  </Router>
);

export default App;