// src/components/About.jsx
import React from 'react';

const About = () => {
  return (
    <section id="about" className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-bold mb-6 text-[#2F3437]">About TravelSquad</h2>
          <div className="space-y-6 text-gray-600">
            <p className="text-lg">
            At TravelSquad, we believe that the best trips are more than destinations – they’re
            unforgettable experiences that become stories you’ll tell for years. Our mission is
            to eliminate the stress of trip planning, so you can focus on what matters most: 
            creating unforgettable memories.
            </p>
            <p className="text-lg">
              Over the years, we’ve not only gathered our own wealth of experiences, 
              but also built a network of fellow travelers who’ve shared invaluable tips, 
              insights, and inspiring stories. By weaving together these diverse experiences, 
              we ensure that each TravelSquad itinerary is as rich as it is reliable. 
            </p>
            <p className="text-lg">
            Our itineraries are crafted with you in mind, balancing adventure with ease. 
            That’s why each itinerary includes essential information on kosher food options,
            nearby Chabads, and other Jewish-friendly resources – all alongside exciting, 
            one-of-a-kind adventures you won’t find anywhere else.
            </p>
            <p className="text-lg">
            Take our blueprint, our learned lessons and mistakes, and turn your dreams into
            lifelong memories. Your next great adventure starts here.
            </p>

          </div>
        </div>
      </div>
    </section>
  );
};

export default About;